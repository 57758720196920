import * as React from "react";
import { Grid, Typography, ListItem } from "@mui/material";
import { colors } from "../../../styles/colors";
import RenderHTMLComponent from "app/components/RenderHtmlComponent";
import RenderJobDescriptionHTMLComponent from "app/components/RenderJobDescriptionHTMLComponent";

interface JobDescriptionProps {
  job: any;
}

const JobDescription = (props: JobDescriptionProps) => {
  return (
    <Grid container spacing={1} sx={{ my: 3 }}>
      <Grid item lg={9} md={9} sm={9} xs={12}>
        <RenderJobDescriptionHTMLComponent
          htmlString={props?.job?.job?.description}
        />
      </Grid>
    </Grid>
  );
};

export default JobDescription;
