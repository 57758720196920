import * as React from "react";
import {
  Grid,
  Typography,
  Button,
  IconButton,
  Box,
  Avatar,
  useMediaQuery,
  Chip
} from "@mui/material";
import images from "assets/images/images";
import { colors } from "../../../styles/colors";
import { statusMapping } from "utils/constants";
import { getDateInFormat } from "utils/helpers";
import theme from "styles/theme";
import { useHistory, useParams } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import HorizontalDivider from "app/components/HorizontalDivider";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";

interface JobInfoProps {
  job: any;
  refetch: any;
  socialShareModelOpen: any;
  setSocialShareModelOpen: any;
  updateJobStatus: any;
  deleteJob: any;
}

const JobInfo = (props: JobInfoProps) => {
  const { setSocialShareModelOpen, updateJobStatus, deleteJob } = props;
  const { jobId }: any = useParams();
  const history = useHistory();
  const matchesXs = useMediaQuery(theme.breakpoints.only("xs"));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item sx={{ display: "flex", alignItems: "start" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box>
              <Avatar
                sx={{
                  height: 50,
                  width: 50,
                  backgroundColor: colors.primaryBlue
                }}
              >
                {props?.job?.job?.title ? props?.job?.job?.title.charAt(0) : ""}
              </Avatar>
            </Box>
            <Box sx={{ ml: 2 }}>
              <Typography
                fontSize="24px"
                fontWeight={600}
                color={`${colors.primaryBlue}E5`}
              >
                {props?.job?.job?.title}
              </Typography>
              <Typography fontSize="14px" color={colors.fontLight}>
                {props?.job?.job?.createdBy?.organization?.name || ""}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", ml: 6, mt: 0.5 }}>
            <Chip
              label={
                <Typography fontSize={15}>
                  {statusMapping[props?.job?.job?.status || 0]}
                </Typography>
              }
              sx={{
                backgroundColor:
                  props?.job?.job?.status === 21
                    ? colors.chipYellow
                    : props?.job?.job?.status === 16
                    ? colors.danger
                    : props?.job?.job?.status === 22
                    ? colors.chipTealGreen
                    : props?.job?.job?.status === 1
                    ? colors.chipGreen
                    : props?.job?.job?.status === 17
                    ? colors.chipViolet
                    : colors.chipYellow,
                color: colors.secondary,
                borderRadius: "50px",
                px: 1.5,
                mr: 2
              }}
            />

            <Chip
              label={
                <Typography fontSize={15}>
                  {" "}
                  {props?.job?.job?.jobType === "JOB" ? "Job" : "Internship"}
                </Typography>
              }
              sx={{
                backgroundColor:
                  props?.job?.job?.jobType === "JOB"
                    ? colors.chipBlue
                    : colors.chipOrange,
                color: colors.secondary,
                borderRadius: "50px",
                px: 1.5,
                mr: 2
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          lg={4}
          md={5}
          sm={5}
          xs={12}
          sx={{
            display: matchesXs ? "none" : "flex",
            justifyContent: "end",
            alignItems: "center"
          }}
        >
          <Chip
            label={
              <Typography fontSize={15}>{`(${
                props?.job?.job?.numberOfViews || 0
              } Views)`}</Typography>
            }
            sx={{
              backgroundColor: colors.lightBlueChip,
              color: colors.primaryBlue,
              borderRadius: "50px",
              px: 1.5,
              mr: 2
            }}
          />
          <Button
            variant="outlined"
            sx={{
              color: colors.secondary,
              backgroundColor: colors.primaryBlue,
              width: "130px",
              borderRadius: "1px",
              "&:hover": {
                backgroundColor: colors.primaryBlueDark,
                color: colors.secondary
              }
            }}
            onClick={() => {
              // history.push(`/employer-job-appliedcanditate/${jobId}`);
              history.push({
                pathname: `/employer-job-appliedcanditate/${jobId}`,
                state: { jobTitle: props?.job?.job?.title }
              });
            }}
          >
            Applicants
          </Button>
          <Box sx={{ ml: 1 }}>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              sx={{ color: colors.primaryBlue, fontSize: 26 }}
            >
              <MoreVertIcon color="inherit" fontSize="inherit" />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button"
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  width: "auto"
                }
              }}
            >
              {props?.job?.job?.status === 0 && (
                <>
                  <MenuItem
                    onClick={() => {
                      history.push(`/employer-add-job/${props?.job?.job?.id}`);
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: "18px",
                        color: colors.primaryBlue,
                        display: "flex"
                      }}
                    >
                      <BorderColorOutlinedIcon
                        fontSize="inherit"
                        color="inherit"
                        sx={{ marginRight: "12px" }}
                      />
                    </Box>
                    Edit Details
                  </MenuItem>
                  <HorizontalDivider />
                </>
              )}
              <>
                <MenuItem
                  onClick={() => {
                    setSocialShareModelOpen(true);
                    handleClose();
                  }}
                  disabled={
                    props?.job?.job?.status === 0 ||
                    props?.job?.job?.status === 16
                      ? true
                      : false
                  }
                >
                  <img
                    src={images.share_icon_blue}
                    width={16}
                    style={{ marginRight: "12px" }}
                  />
                  Share
                </MenuItem>
              </>
              <>
                <MenuItem
                  sx={{ my: 0.5 }}
                  onClick={() => {
                    history.push("/employer-add-job/new", {
                      jobId: props?.job?.job?.id
                    });
                  }}
                >
                  <ContentCopyIcon
                    style={{ fontSize: 19, color: colors.primaryBlue }}
                  />
                  <Typography fontSize={14} color={colors.fontPrimary} ml={1.7}>
                    Copy
                  </Typography>
                </MenuItem>
                <HorizontalDivider />
              </>
              {/* {(props?.job?.job?.status === 21 || props?.job?.job?.status === 22) && (
              <>
                <MenuItem
                  sx={{ my: 0.5 }}
                  onClick={() => {
                    setSelectedJob(data);
                    setRefreshJobModalOpen(true);
                    handleClose();
                  }}
                >
                  <img src={images.refresh_icon_blue} width={17} />
                  <Typography fontSize={14} color={colors.fontPrimary} ml={2}>
                    Refresh
                  </Typography>
                </MenuItem>
                <HorizontalDivider />
              </>
            )} */}
              {props?.job?.job?.status !== 0 &&
                props?.job?.job?.status !== 16 &&
                props?.job?.job?.status !== 22 && (
                  <>
                    <MenuItem
                      sx={{ my: 0.5 }}
                      onClick={() => {
                        updateJobStatus({
                          variables: {
                            payload: {
                              id: props?.job?.job?.id,
                              status: 16
                            }
                          }
                        });
                        handleClose();
                      }}
                    >
                      <img src={images.close_icon_blue} width={21} />
                      <Typography
                        fontSize={14}
                        color={colors.fontPrimary}
                        ml={1.4}
                      >
                        Close
                      </Typography>
                    </MenuItem>
                    <HorizontalDivider />
                  </>
                )}
              {props?.job?.job?.status === 0 && (
                <>
                  <HorizontalDivider />
                  <MenuItem
                    onClick={() => {
                      deleteJob({
                        variables: {
                          ids: [props?.job?.job?.id]
                        }
                      });
                      handleClose();
                    }}
                  >
                    <img
                      src={images.delete_icon_red}
                      width={16}
                      style={{ marginRight: "12px" }}
                    />
                    Delete
                  </MenuItem>
                </>
              )}
            </Menu>
          </Box>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" alignItems="flex-end">
        <Grid item>
          <Grid container spacing={1} alignItems="center" sx={{ mt: 2 }}>
            <Grid item sx={{ mr: 2, display: "flex" }}>
              <img src={images.student_location_icon_blue} height={16} />
              <Typography color={colors.primaryBlue} fontSize="13px" ml={0.5}>
                {props?.job?.job?.state?.name}
              </Typography>
            </Grid>
            <Grid item sx={{ mr: 2, display: "flex" }}>
              <img src={images.student_positions_icon_blue} height={16} />
              <Typography color={colors.primaryBlue} fontSize="13px" ml={0.5}>
                {props?.job?.job?.positions} Positions
              </Typography>
            </Grid>
            <Grid item sx={{ mr: 2, display: "flex" }}>
              <img src={images.student_experience_icon_blue} height={14} />
              {props?.job?.job?.minimumExperience !== null &&
              props?.job?.job?.maximumExperience !== null ? (
                <Typography color={colors.primaryBlue} fontSize="13px" ml={0.5}>
                  {props?.job?.job?.minimumExperience
                    ? props?.job?.job?.minimumExperience
                    : ""}{" "}
                  to{" "}
                  {props?.job?.job?.maximumExperience
                    ? props?.job?.job?.maximumExperience
                    : ""}{" "}
                  years
                </Typography>
              ) : (
                <Typography color={colors.fontLight} fontSize="13px" ml={0.5}>
                  Not specified
                </Typography>
              )}
            </Grid>
            <Grid item sx={{ mr: 2, display: "flex" }}>
              <img src={images.student_rupee_icon_blue} height={16} />
              {props?.job?.job?.salaryConfidential ? (
                <Typography color={colors.fontLight} fontSize="13px" ml={0.5}>
                  ** to **
                </Typography>
              ) : props?.job?.job?.stipendType === "fixed" ? (
                <Typography color={colors.primaryBlue} fontSize="13px" ml={0.5}>
                  ₹
                  {props?.job?.job?.minimumRemuneration
                    ? props?.job?.job?.minimumRemuneration
                    : "*"}
                </Typography>
              ) : props?.job?.job?.stipendType === "noStipend" ? (
                <Typography color={colors.fontLight} fontSize="13px" ml={0.5}>
                  No Stipend
                </Typography>
              ) : (
                <Typography color={colors.primaryBlue} fontSize="13px" ml={0.5}>
                  ₹{" "}
                  {props?.job?.job?.minimumRemuneration
                    ? props?.job?.job?.minimumRemuneration
                    : "*"}{" "}
                  to ₹{" "}
                  {props?.job?.job?.maximumRemuneration
                    ? props?.job?.job?.maximumRemuneration
                    : "*"}
                </Typography>
              )}
            </Grid>
            <Grid item sx={{ display: "flex" }}>
              <img src={images.student_education_icon_blue} height={14} />
              <Typography color={colors.primaryBlue} fontSize="13px" ml={0.5}>
                {props?.job?.job?.qualification?.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default JobInfo;
