import * as React from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Grid,
  Paper,
  Button,
  Typography,
  Box,
  useMediaQuery,
  Chip
} from "@mui/material";
import {
  AccountBoxOutlined,
  LocationOnOutlined,
  ModeEditOutlineOutlined,
  PeopleAltOutlined,
  BusinessCenterOutlined,
  PaymentsOutlined,
  SchoolOutlined,
  VisibilityOutlined,
  FiberManualRecord
} from "@mui/icons-material";
import EmployerNavbar from "app/components/EmployerNavbar";
import { colors } from "styles/colors";
import theme from "styles/theme";
import { useQuery, useMutation } from "@apollo/client";
import {
  DELETE_JOBS,
  GET_JOB,
  UPDATE_JOB,
  UPDATE_JOB_STATUS
} from "app/graphql/jobs";
import getStore from "store/zustandStore";
import { statusMapping } from "utils/constants";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import SocialMediaShare from "app/components/SocialMediaShareModal";
import JobInfo from "./JobInfo";
import JobDescription from "./JobDescription";
import JobMetaData from "./JobMetaData";
import SalaryDetails from "./SalaryDetails";
import QualificationDetails from "./QualificationDetails";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useSnackbar } from "notistack";

const JobViewDetails = () => {
  const history = useHistory();
  const { jobId }: any = useParams();
  const setLoading = getStore((state: any) => state.setLoading);
  const matchesXs = useMediaQuery(theme.breakpoints.only("xs"));
  const { enqueueSnackbar } = useSnackbar();

  const [jobDetails, setJobDetails] = React.useState<any>({});
  const [socialShareModelOpen, setSocialShareModelOpen] = React.useState(false);

  const {
    data: job,
    loading: jobLoading,
    refetch
  } = useQuery(GET_JOB, {
    variables: {
      id: jobId
    },
    skip: !jobId,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  const [updateJobStatus, { loading: updateJobStatusLoading }] = useMutation(
    UPDATE_JOB_STATUS,
    {
      onCompleted: (data) => {
        const { updateJobStatus } = data;
        const { status, error } = updateJobStatus;
        console.log(data);
        if (status) {
          refetch();
          enqueueSnackbar(error, {
            variant: "success"
          });
        } else {
          enqueueSnackbar(error, {
            variant: "error"
          });
        }
      },
      fetchPolicy: "network-only"
    }
  );

  const [deleteJob, { loading: deleteJobLoading }] = useMutation(DELETE_JOBS, {
    onCompleted: (data) => {
      const { deleteJob } = data;
      const { status, error } = deleteJob;
      if (status) {
        refetch();
        enqueueSnackbar(error, {
          variant: "success"
        });
      } else {
        enqueueSnackbar(error, {
          variant: "error"
        });
      }
    },
    fetchPolicy: "network-only"
  });

  React.useEffect(() => {
    setLoading(jobLoading);
  }, [jobLoading, setLoading]);

  return (
    <>
      <Box
        sx={{
          display: matchesXs ? "flex" : "none",
          alignItems: "center",
          color: colors.primary,
          marginBottom: 2
        }}
        onClick={() => history.goBack()}
      >
        <KeyboardArrowLeftIcon fontSize="small" color="inherit" />
        <Typography
          fontSize={14}
          sx={{ mr: 23.5, color: colors.fontPrimary, cursor: "pointer" }}
        >
          {" "}
          Go Back
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: "flex" }}>
          <Typography
            fontSize="19px"
            fontWeight={500}
            color={colors.fontLighter2}
            onClick={() => history.push("/employer-search-job")}
            sx={{ cursor: "pointer" }}
          >
            Jobs/ Internships
          </Typography>
          <Box sx={{ color: colors.primaryBlue, mx: 1 }}>
            <ChevronRightIcon color="inherit" />
          </Box>
          <Typography
            fontSize="20px"
            fontWeight={500}
            color={colors.fontDarkBlue}
          >
            {job?.job?.title || ""}
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Paper elevation={2} sx={{ p: 3.5 }}>
            <JobInfo
              job={job}
              refetch={refetch}
              socialShareModelOpen={socialShareModelOpen}
              setSocialShareModelOpen={setSocialShareModelOpen}
              updateJobStatus={updateJobStatus}
              deleteJob={deleteJob}
            />
            <JobDescription job={job} />
            <div
              style={{
                height: 1,
                backgroundColor: "#E9E9E9",
                width: "100%"
              }}
            />
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ display: "flex", alignItems: "center", my: 3 }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  color={colors.fontLighter2}
                  fontSize="13px"
                  // ml={0.5}
                  mr={1}
                >
                  Published To:
                </Typography>
                {job?.job?.publishTo === "CAMPUS" &&
                job?.job?.jobCampus?.length > 0 ? (
                  job?.job?.jobCampus?.map((item) => (
                    <Grid key={item?.campus?.name} item>
                      <Chip
                        label={item?.campus?.name}
                        sx={{
                          backgroundColor: colors.lightBlueChip,
                          color: colors.primaryBlue,
                          borderRadius: "2px"
                        }}
                      />
                    </Grid>
                  ))
                ) : job?.job?.publishTo === "LOCATION" &&
                  job?.job?.jobStates?.length > 0 ? (
                  job?.job?.jobStates?.map((item) => (
                    <Grid key={item?.state?.name} item>
                      <Chip
                        label={item?.state?.name}
                        sx={{
                          backgroundColor: colors.lightBlueChip,
                          color: colors.primaryBlue,
                          borderRadius: "2px"
                        }}
                      />
                    </Grid>
                  ))
                ) : (
                  <Grid item>
                    <Chip
                      label={"Everyone"}
                      sx={{
                        backgroundColor: colors.lightBlueChip,
                        color: colors.primaryBlue,
                        borderRadius: "2px"
                      }}
                    />
                  </Grid>
                )}
              </Box>
            </Grid>
            <div
              style={{
                height: 1,
                backgroundColor: "#E9E9E9",
                width: "100%"
              }}
            />
            <JobMetaData job={job} />
            <div
              style={{
                height: 1,
                backgroundColor: "#E9E9E9",
                width: "100%"
              }}
            />
            <SalaryDetails job={job} />
            <div
              style={{
                height: 1,
                backgroundColor: "#E9E9E9",
                width: "100%"
              }}
            />
            <QualificationDetails job={job} />
            <Box
              sx={{
                width: "100%",
                display: matchesXs ? "flex" : "none",
                justifyContent: "space-between"
              }}
            >
              <Box
                sx={{
                  display: "flex"
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    color: colors.secondary,
                    backgroundColor: colors.primaryBlue,
                    width: "130px",
                    borderRadius: "1px",
                    "&:hover": {
                      backgroundColor: colors.primaryBlueDark,
                      color: colors.secondary
                    }
                  }}
                  onClick={() => {
                    history.push(`/employer-job-appliedcanditate/${jobId}`);
                  }}
                >
                  Applicants
                </Button>
              </Box>
              <SocialMediaShare
                open={socialShareModelOpen}
                setOpen={setSocialShareModelOpen}
                id={job?.job?.id}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export const EmployerJobViewDetails = (props) => {
  return <EmployerNavbar mainComponent={<JobViewDetails />} />;
};
